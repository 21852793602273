import React from 'react';

import isologoRed from './assets/images/CH-Isologo-RED-fBlanco.png'

export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper jzih1dpqqrg-editor_css' },
  page: { className: 'home-page l46c34skm2t-editor_css' },
  logo: {
    className: 'header3-logo jzjgnya1gmn-editor_css',
    children: 'https://cryptohouse.services/images/text_logo.png',
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'products',
        className: 'header3-item l7fsd61tpab-editor_css',
        children: {
          href: '#products',
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <p>Products</p>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              name: 'text',
              className: 'l7fsk6ulzgp-editor_css',
            },
          ],
        },
        subItem: [
          {
            className: 'item-sub l75n13a0rid-editor_css',
            children: {
              className: 'item-sub-item l75n0cizhca-editor_css',
              href: '#rental',
              children: [
                {
                  name: 'rental',
                  className: 'l75m1ppnsu-editor_css',
                  children: (
                    <span>
                      <span>
                        <p>Rental Marketplace</p>
                      </span>
                    </span>
                  ),
                },
              ],
            },
          },
          {
            className: 'item-sub',
            children: {
              className: 'item-sub-item l75n4ijr75-editor_css',
              href: '#tokenization',
              children: [
                {
                  name: 'tokenization',
                  className: '',
                  children: (
                    <span>
                      <p>Tokenization Tool</p>
                    </span>
                  ),
                },
              ],
            },
          },
          {
            className: 'item-sub l75n13a0rid-editor_css',
            children: {
              className: 'item-sub-item l75n0cizhca-editor_css',
              href: '#metaverse',
              children: [
                {
                  name: 'metaverse',
                  className: 'l75m1ppnsu-editor_css',
                  children: (
                    <span>
                      <span>
                        <p>Metaverse Rentals</p>
                      </span>
                    </span>
                  ),
                },
              ],
            },
          },
          {
            className: 'item-sub',
            children: {
              className: 'item-sub-item l75n06xsij-editor_css',
              href: '#dao',
              children: [
                {
                  name: 'dao',
                  className: 'l75m1hrjy2-editor_css',
                  children: (
                    <span>
                      <span>
                        <span>
                          <p>The DAO</p>
                        </span>
                      </span>
                    </span>
                  ),
                },
              ],
            },
          },
        ],
      },
      {
        name: 'us',
        className: 'header3-item l7fs5mxux3a-editor_css',
        children: {
          href: '',
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <p>Us</p>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
        subItem: [
          {
            className: 'item-sub l75n13a0rid-editor_css',
            children: {
              className: 'item-sub-item l75n0cizhca-editor_css',
              href: '#token',
              children: [
                {
                  name: 'token-menu',
                  className: 'l75m1ppnsu-editor_css',
                  children: (
                    <span>
                      <span>
                        <p>Token</p>
                      </span>
                    </span>
                  ),
                },
              ],
            },
          },
          {
            className: 'item-sub',
            children: {
              className: 'item-sub-item l75n4ijr75-editor_css',
              href: '#ispo',
              children: [
                {
                  name: 'ispo',
                  className: '',
                  children: (
                    <span>
                      <p>ISPO / Pool</p>
                    </span>
                  ),
                },
              ],
            },
          },
          {
            className: 'item-sub l75n13a0rid-editor_css',
            children: {
              className: 'item-sub-item l75n0cizhca-editor_css',
              href: '#roadmap',
              children: [
                {
                  name: 'roadmap',
                  className: 'l75m1ppnsu-editor_css',
                  children: (
                    <span>
                      <span>
                        <p>Roadmap</p>
                      </span>
                    </span>
                  ),
                },
              ],
            },
          },
          {
            className: 'item-sub',
            children: {
              className: 'item-sub-item l75n06xsij-editor_css',
              href: '#team',
              children: [
                {
                  name: 'team',
                  className: 'l75m1hrjy2-editor_css',
                  children: (
                    <span>
                      <span>
                        <span>
                          <p>Team</p>
                        </span>
                      </span>
                    </span>
                  ),
                },
              ],
            },
          },
          {
            className: 'item-sub',
            children: {
              className: 'item-sub-item l75n4sfh9bp-editor_css',
              href: '#catalyst',
              children: [
                {
                  name: 'catalyst',
                  className: '',
                  children: (
                    <span>
                      <p>Catalyst</p>
                    </span>
                  ),
                },
              ],
            },
          },
          {
            className: 'item-sub',
            children: {
              className: 'item-sub-item l75n4vuod7k-editor_css',
              href: '#more',
              children: [
                {
                  name: 'more',
                  className: '',
                  children: (
                    <span>
                      <span>
                        <p>More ...</p>
                      </span>
                    </span>
                  ),
                },
              ],
            },
          },
        ],
      },
      {
        name: 'help',
        className: 'header3-item l7fs8dmpstt-editor_css',
        children: {
          href: 'https://discord.gg/Eq2SPtApnG',
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>Help</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              name: 'text',
              className: 'l7fsk5hif6-editor_css',
            },
          ],
          className: 'l7fskbow809-editor_css',
        },
      },
      {
        name: 'app',
        className: 'header3-item',
        children: {
          href: 'https://app.cryptohouse.services',
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>dApp</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
        subItem: [
          {
            className: 'item-sub',
            children: {
              className: 'item-sub-item',
              children: [
                {
                  name: 'image0',
                  className: 'item-image',
                  children: 'https://cryptohouse.services/images/icon-medium.png',
                },
                {
                  name: 'title',
                  className: 'item-title l75nlqvovx-editor_css',
                  children: (
                    <span>
                      <p>Coming Soon</p>
                    </span>
                  ),
                },
              ],
            },
          },
        ],
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5 l46k7d6swr9-editor_css' },
  page: { className: 'home-page banner5-page l43jgtcs54s-editor_css' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
                                  <span>
                                    <b>The Web3 Real Estate Ecosystem</b>
                                  </span>

        ),
        className: 'banner5-title l43jjddlso-editor_css',
      },
      {
        name: 'content',
        className: 'banner5-content l44kar0z8qu-editor_css',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <span>
                                          <span><br></br><ul>
                                            <li>
                                              Endows cryptocurrencies with real
                                              usability, connecting the
                                              blockchain with the physical
                                              world.
                                            </li>
                                            <li>
                                              Connects the crypto and real estate sector, 
                                              freeing up a great flow of capital that can
                                              flow between both.
                                            </li>
                                            <li>
                                              We bring new useful tools that help promote crypto adoption
                                              and new investment opportunities.
                                            </li>
                                            <li>
                                              Decentralized and safe. Our smart contracts safeguard your funds
                                              until you are comfortable with your accomodation.
                                            </li></ul>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper l46lbx94tn9-editor_css',
        children: {
          href: 'https://w3s.link/ipfs/QmaRhA4vBMAixrUb7oD8GC4dSJjy9Zi7iNm3eEFRAfsNSx',
          className: 'banner5-button l46kqf15ym-editor_css',
          type: 'primary',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <p>WHITEPAPER v1</p>
                  </span>
                </span>
              </span>
            </span>
          ),
          target: '_blank',
        },
      },
    ],
  },
  image: {
    className: 'banner5-image l4d8wim2ek-editor_css',
    children:
    'https://cryptohouse.services/images/PRINCIPAL.png',
  },
};
export const Feature00DataSource = {
  wrapper: {
    className: 'home-page-wrapper content0-wrapper l7fqir1zsnh-editor_css',
  },
  page: { className: 'home-page content0 l7fqj3labw-editor_css' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span id="products">
              <h2>
                Products
              </h2>
            </span>
          </span>
        ),
        className: 'l7fr8rxsb4l-editor_css',
      },
    ],
  },
  childWrapper: { className: 'content0-block-wrapper', children: [] },
};
export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: 'https://cryptohouse.services/images/MARKETPLACE.png',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <span>
          <h3>
            Rental Marketplace
          </h3>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <span>
          <p>
            Explore the real estate market in the blockchain, meet other
            community members. Find your next vacation spot. No middlemen!
          </p>
          <p>A free, safe and decentralized real estate market</p>
        </span>
      </span>
    ),
  },
};
export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children: 'https://cryptohouse.services/images/TOKENIZACION.png',
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title l7frc7w0u6-editor_css',
    children: (
      <span>
        <span>
          <span>
            <span>
              <span>
                <span>
                  <h3>
                    Real Estate Tokenization
                  </h3>
                </span>
              </span>
            </span>
          </span>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content2-content',
    children: (
      <span>
        <p>
          Divide a property into NFTs that can be traded and earn passive
          income.
        </p>
        <p>
          Discover the investment opportunities from around the world brought to
          you by tokenization.
        </p>
        <p>
          Tokenization is to the real estate sector what the stock exchange was
          for the entrepreneurial sector.
        </p>
      </span>
    ),
  },
};
export const Feature30DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: 'https://cryptohouse.services/images/METAVERSE.png',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title l7frchv4wq-editor_css',
    children: (
      <span>
        <span>
          <span>
            <span>
              <span>
                <h3>
                  Metaverse Rentals
                </h3>
              </span>
            </span>
          </span>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <p>
          The real estate market in the metaverse is now a multi-million dollar
          reality. We are creating a marketplace specifically for trading real
          estate properties inside the virtual world.
        </p>
        <p>
          Need to host an event but can't get your hands on a well located
          venue? You have come to the right place!
        </p>
      </span>
    ),
  },
};
export const Feature40DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children: 'https://cryptohouse.services/images/DAO.png',
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title l7frcmtm8um-editor_css',
    children: (
      <span>
        <span>
          <h3>
            The Dao
          </h3>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content2-content',
    children: (
      <span>
        <p>
          Our vision is to become a DAO, giving the power back to the users
          through voting rounds to decide on real estate projects to be funded
          and protocol updates
        </p>
      </span>
    ),
  },
};
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper l7frcv6ao-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
/*      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      }, */
      {
        name: 'title',
        children: (
          <span id="token">
            <span id="token">
              <h2>
                Token
              </h2>
            </span>
          </span>
        ),
        className: 'title-h1 l7frcr1l5br-editor_css',
      },
      {
        name: 'content',
        children: (
          <span id="token">
            <span id="token">
              <p>
                The $CH token is the main currency in the platform, it is
                designed to have great usability and therefore, buying pressure.<br></br>
                This way we focus on long term sustainability.
              </p>
            </span>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (
          <span id="token">
            <p>
              In future iterations, as the DAO is constituted, $CH will also
              become the governance token.
            </p>
          </span>
        ),
        className: 'title-content',
      },
    ],
  },
  button: {
    className: '',
    children: {
      a: {
        className: 'button l7eddufpwoq-editor_css',
        href: '#',
        children: (
          <span>
            <p>BUY - SOON</p>
          </span>
        ),
      },
    },
  },
};
export const Feature60DataSource = {
  wrapper: { className: 'home-page-wrapper feature6-wrapper' },
  OverPack: { className: 'home-page feature6', playScale: 0.3 },
  Carousel: {
    className: 'feature6-content l7e04vjji8-editor_css',
    dots: true,
    wrapper: { className: 'feature6-content-wrapper l7dzzwd7jbs-editor_css' },
    titleWrapper: {
      className: 'feature6-title-wrapper',
      barWrapper: {
        className: 'feature6-title-bar-wrapper',
        children: { className: 'feature6-title-bar' },
      },
      title: { className: 'feature6-title' },
    },
    children: [
      {
        title: {
          className: 'feature6-title-text l46mgc360g-editor_css',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <p>
                                      <b>ISPO</b>
                                    </p>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
        className: 'feature6-item',
        name: 'block0',
        children: [
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child0',
            number: {
              className: 'feature6-number l46mfwetsll-editor_css',
              unit: {
                className: 'feature6-unit l46mvncufoh-editor_css',
                children: (
                  <span>
                    <span>
                      <p>wallets</p>
                    </span>
                  </span>
                ),
              },
              toText: true,
              children: '0',
            },
            children: {
              className: 'feature6-text l46mydvvw5f-editor_css',
              children: (
                <span>
                  <b>Delegators</b>
                </span>
              ),
            },
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child1',
            number: {
              className: 'feature6-number l46ksuq4ggb-editor_css',
              unit: {
                className: 'feature6-unit l46mw4xrhki-editor_css',
                children: (
                  <span>
                    <span>
                      <span>
                        <p>% saturated</p>
                      </span>
                    </span>
                  </span>
                ),
              },
              toText: true,
              children: '0',
            },
            children: {
              className: 'feature6-text l46mygo2lv-editor_css',
              children: (
                <span>
                  <b>Saturation</b>
                </span>
              ),
            },
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child2',
            number: {
              className: 'feature6-number l46mfza7acq-editor_css',
              unit: {
                className: 'feature6-unit l46mw9ld5f-editor_css',
                children: (
                  <span>
                    <p>Million ADA</p>
                  </span>
                ),
              },
              toText: true,
              children: '0',
            },
            children: {
              className: 'feature6-text l46myisu2x-editor_css',
              children: (
                <span>
                  <b>Live Stake</b>
                </span>
              ),
            },
          },
        ],
      },
      {
        title: {
          className: 'feature6-title-text l46mgc360g-editor_css',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <p>
                                    <b>COMING SOON</b>
                                  </p>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
        className: 'feature6-item',
        name: 'elem~l7e0bxaa1v',
        children: [
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child0',
            number: {
              className: 'feature6-number l46mfwetsll-editor_css',
              unit: {
                className: 'feature6-unit l46mvncufoh-editor_css',
                children: (
                  <span>
                    <span>
                      <p>wallets</p>
                    </span>
                  </span>
                ),
              },
              toText: true,
              children: '0',
            },
            children: {
              className: 'feature6-text l46mydvvw5f-editor_css',
              children: (
                <span>
                  <b>Delegators</b>
                </span>
              ),
            },
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child1',
            number: {
              className: 'feature6-number l46ksuq4ggb-editor_css',
              unit: {
                className: 'feature6-unit l46mw4xrhki-editor_css',
                children: (
                  <span>
                    <span>
                      <span>
                        <p>% saturated</p>
                      </span>
                    </span>
                  </span>
                ),
              },
              toText: true,
              children: '0',
            },
            children: {
              className: 'feature6-text l46mygo2lv-editor_css',
              children: (
                <span>
                  <b>Saturation</b>
                </span>
              ),
            },
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child2',
            number: {
              className: 'feature6-number l46mfza7acq-editor_css',
              unit: {
                className: 'feature6-unit l46mw9ld5f-editor_css',
                children: (
                  <span>
                    <p>Million ADA</p>
                  </span>
                ),
              },
              toText: true,
              children: '0',
            },
            children: {
              className: 'feature6-text l46myisu2x-editor_css',
              children: (
                <span>
                  <b>Live Stake</b>
                </span>
              ),
            },
          },
        ],
      },
    ],
    autoplay: false,
  },
};
export const Feature80DataSource = {
  wrapper: { className: 'home-page-wrapper feature8-wrapper' },
  page: { className: 'home-page feature8' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature8-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'feature8-title-h1 l7frd6th41k-editor_css',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <h2>
                      Roadmap
                    </h2>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'feature8-title-content l7fsghvogds-editor_css',
        children: (
          <span>
            <span>
              <span>
                <p>You can find the main milestones in this section</p>
              </span>
            </span>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'feature8-button-wrapper',
    children: [],
  },
  Carousel: {
    dots: false,
    className: 'feature8-carousel',
    wrapper: { className: 'feature8-block-wrapper' },
    children: {
      className: 'feature8-block',
      titleWrapper: {
        className: 'feature8-carousel-title-wrapper',
        title: { className: 'feature8-carousel-title' },
      },
      children: [
        {
          name: 'block0',
          className: 'feature8-block-row',
          gutter: 120,
          title: {
            className: 'feature8-carousel-title-block l7frderhwi-editor_css',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <p>
                                  <b>2022</b>
                                </p>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          children: [
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child0',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://cryptohouse.services/images/mini-arrow.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://cryptohouse.services/images/time1.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: (
                      <span>
                        <span>
                          <span>
                            <p>Q1</p>
                          </span>
                        </span>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children: (
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <span>
                                          <p>
                                            - Concept development and
                                            validation<br />
                                            <span>
                                              - Technical evaluation<br />
                                            </span>
                                            <span>
                                              &nbsp;of the DAPP and native token<br />
                                            </span>
                                            <span>
                                              - Creation of the web platform
                                              and technical documentation of the
                                              project<br />
                                            </span>
                                            <span>
                                              - Token and platform planning<br />
                                            </span>
                                            <br />
                                          </p>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    ),
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child1',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://cryptohouse.services/images/mini-arrow.svg',
              },
              children: {
                className: 'feature8-block-child l7ed29c5h7-editor_css',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://cryptohouse.services/images/time1.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title l4egn9kbnt-editor_css',
                    children: (
                      <span>
                        <p>Q2</p>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content l4egngngq3-editor_css',
                    children: (
                      <span>
                        <span>
                          <span>
                            <span>
                              <p>
                                - Creation of social platforms<br />
                                <span>
                                  - Project presentation and learning content
                                  outreach.<br />
                                </span>- Team recruitment: management,
                                technical, legal, marketing, communicators,
                                ambassadors, etc.&nbsp;<br />
                              </p>
                            </span>
                          </span>
                        </span>
                      </span>
                    ),
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child2',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://cryptohouse.services/images/mini-arrow.svg',
              },
              children: {
                className: 'feature8-block-child l7ed2nmyb6-editor_css',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image l75nnih325u-editor_css',
                    children:
                      'https://cryptohouse.services/images/time1.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title l4egncci79d-editor_css',
                    children: (
                      <span>
                        <span>
                          <p>Q3</p>
                        </span>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content l4egnixa5at-editor_css',
                    children: (
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <p>
                                    - Constant monitoring and improvement of
                                    strategies in social networks.<br />
                                    <span>
                                      - Launch of airdrop strategy<br />
                                    </span>- Implementation of marketing
                                    strategies that enhance the scope and
                                    introduce the project, the token, the DAPP
                                    and the ISPO.&nbsp;<span>
                                      <br />
                                    </span>
                                    <br />
                                  </p>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    ),
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child3',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://cryptohouse.services/images/mini-arrow.svg',
              },
              children: {
                className: 'feature8-block-child l7ed5gf6ic-editor_css',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image l75nomv8at6-editor_css',
                    children:
                      'https://cryptohouse.services/images/time1.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title l4egnellvu-editor_css',
                    children: (
                      <span>
                        <p>Q4</p>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content l4egnl4rg0t-editor_css',
                    children: (
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <span>
                                          <span>
                                            <span>
                                              <span>
                                                <p>
                                                  - First airdrop<br />
                                                  <span>
                                                    - Telegram and Discord
                                                    communities development.{' '}
                                                    <br />- Catalyst Fund 10
                                                    participation.<br />-
                                                    Meetings with institutional
                                                    investors thanks to our
                                                    presence in Entebbe.<br />
                                                  </span>
                                                  <span>
                                                    <br />
                                                  </span>
                                                  <br />
                                                </p>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    ),
                  },
                ],
              },
            },
          ],
        },
        {
          name: 'block1',
          className: 'feature8-block-row',
          gutter: 120,
          title: {
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <p>
                                <b>2023</b>
                              </p>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
            className: 'feature8-carousel-title-block',
          },
          children: [
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child0',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://cryptohouse.services/images/mini-arrow.svg',
              },
              children: {
                className: 'feature8-block-child l7ed1o7p34-editor_css',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://cryptohouse.services/images/time1.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title l4egqf0ikb-editor_css',
                    children: (
                      <span>
                        <p>Q1</p>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content l4egqs42clq-editor_css',
                    children: (
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <p>
                                  - Pool creation and ISPO<br />- Publishing
                                  platform documentation.<br />- Promotion
                                  through our ambassador program
                                </p>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    ),
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child1',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://cryptohouse.services/images/mini-arrow.svg',
              },
              children: {
                className: 'feature8-block-child l7ed44r6w4-editor_css',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://cryptohouse.services/images/time1.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title l4egr0y8ajp-editor_css',
                    children: (
                      <span>
                        <p>Q2</p>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content l4egr4cxu9-editor_css',
                    children: (
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <p>
                                      - Delivery of vesting and escrow
                                      contracts.<br />- Partnership with a DEX
                                      for token launch and farming.<br />
                                      <span>
                                        - Translation to 4 languages<br />-
                                        Private Testnet
                                      </span>
                                    </p>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    ),
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child2',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://cryptohouse.services/images/mini-arrow.svg',
              },
              children: {
                className: 'feature8-block-child l7ed4a9rl6-editor_css',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://cryptohouse.services/images/time1.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title l4egs2argv7-editor_css',
                    children: (
                      <span>
                        <p>Q3</p>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content l4egrybh8qu-editor_css',
                    children: (
                      <span>
                        <span>
                          <span>
                            <p>
                              - Liquidity pool creation, launch and trading of
                              our native token $CH
                            </p>
                          </span>
                        </span>
                      </span>
                    ),
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child3',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://cryptohouse.services/images/mini-arrow.svg',
              },
              children: {
                className: 'feature8-block-child l7ed4ekconj-editor_css',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://cryptohouse.services/images/time1.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title l4egs58u529-editor_css',
                    children: (
                      <span>
                        <p>Q4</p>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content l4egs8cha7o-editor_css',
                    children: (
                      <span>
                        <span>
                          <p>
                            - $CH token farming<br />- dApp v1.0 launch, rental
                            marketplace<br />- Airdrops<br />- ISPO rewards and
                            previous airdrops release
                          </p>
                        </span>
                      </span>
                    ),
                  },
                ],
              },
            },
          ],
        },
        {
          name: 'elem~l7ec4avcr2j',
          className: 'feature8-block-row',
          gutter: 120,
          title: {
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <p>
                              <b>BEYOND</b>
                            </p>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
            className: 'feature8-carousel-title-block',
          },
          children: [
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child0',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://cryptohouse.services/images/mini-arrow.svg',
              },
              children: {
                className: 'feature8-block-child l7ed6vxqat6-editor_css',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://cryptohouse.services/images/time1.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title l4egqf0ikb-editor_css',
                    children: (
                      <span>
                        <span>
                          <p>2024</p>
                        </span>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content l4egqs42clq-editor_css',
                    children: (
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <p>
                                      - dApp v2.0 launch, real estate
                                      tokenization and marketplace
                                    </p>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    ),
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child1',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://cryptohouse.services/images/mini-arrow.svg',
              },
              children: {
                className: 'feature8-block-child l7ed74yf3j-editor_css',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://cryptohouse.services/images/time1.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title l4egr0y8ajp-editor_css',
                    children: (
                      <span>
                        <span>
                          <p>2025</p>
                        </span>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content l4egr4cxu9-editor_css',
                    children: (
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <span>
                                          <p>
                                            - dApp v3.0 launch, metaverse
                                            marketplace
                                          </p>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    ),
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child2',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://cryptohouse.services/images/mini-arrow.svg',
              },
              children: {
                className: 'feature8-block-child l7ed7alc0he-editor_css',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://cryptohouse.services/images/time1.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title l4egs2argv7-editor_css',
                    children: (
                      <span>
                        <span>
                          <p>2026</p>
                        </span>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content l4egrybh8qu-editor_css',
                    children: (
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <p>
                                  - Development and implementation of consensus
                                  and governance protocol
                                </p>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    ),
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child3',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://cryptohouse.services/images/mini-arrow.svg',
              },
              children: {
                className: 'feature8-block-child l7ed7fmg7qg-editor_css',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://cryptohouse.services/images/time1.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title l4egs58u529-editor_css',
                    children: (
                      <span>
                        <span>
                          <p>BEYOND</p>
                        </span>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content l4egs8cha7o-editor_css',
                    children: (
                      <span>
                        <span>
                          <span>
                            <span>
                              <p>- To be decided by the DAO</p>
                            </span>
                          </span>
                        </span>
                      </span>
                    ),
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  },
};
export const Teams20DataSource = {
  wrapper: {
    className: 'home-page-wrapper teams2-wrapper l7frwm7ea3-editor_css',
  },
  page: { className: 'home-page teams2' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <h2>
                Team
              </h2>
            </span>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'block-wrapper',
    gutter: 72,
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 8,
        xs: 24,
        image: {
          name: 'image',
          className: 'teams2-image',
          children:
            'https://cryptohouse.services/team/Iago.png',
        },
        titleWrapper: {
          className: 'teams2-textWrapper',
          children: [
            {
              name: 'title',
              className: 'teams2-title l7frjymv2m-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          Yago
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams2-job',
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        CEO &amp; Founder
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams2-content',
              children: (
                <span>
                  <p>Core Team</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 8,
        xs: 24,
        image: {
          name: 'image',
          className: 'teams2-image',
          children: 'https://cryptohouse.services/team/Alf.png',
        },
        titleWrapper: {
          className: 'teams2-textWrapper',
          children: [
            {
              name: 'title',
              className: 'teams2-title',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>
                            Alfred [TOPO]<span role="img" aria-labelledby="lightning">⚡</span>
                          </p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams2-job l7fs3et6h77-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>
                           Cardano Tech Officer
                          </p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams2-content l7fshmiphim-editor_css',
              children: (
                <span>
                  <p>Core Team</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 8,
        xs: 24,
        image: {
          name: 'image',
          className: 'teams2-image',
          children: 'https://cryptohouse.services/team/Andros.png',
        },
        titleWrapper: {
          className: 'teams2-textWrapper',
          children: [
            {
              name: 'title',
              className: 'teams2-title l7frobqxw4-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        Andros
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams2-job l7fsh3g69kk-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        CFO
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams2-content l7fshixik8p-editor_css',
              children: (
                <span>
                  <p>Core Team</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block5',
        className: 'block',
        md: 8,
        xs: 24,
        image: {
          name: 'image',
          className: 'teams2-image',
          children:
            'https://cryptohouse.services/team/Yaiza.png',
        },
        titleWrapper: {
          className: 'teams2-textWrapper l7fsi50e2xo-editor_css',
          children: [
            {
              name: 'title',
              className: 'teams2-title l7frr7amlf-editor_css',
              children: (
                <span>
                  <p>Yaiza</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams2-job l7fsi2vd4ri-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        Social Media Manager
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams2-content',
              children: (
                <span>
                  <p>Extended Team</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 8,
        xs: 24,
        image: {
          name: 'image',
          className: 'teams2-image',
          children:
            'https://cryptohouse.services/team/Hernan.png',
        },
        titleWrapper: {
          className: 'teams2-textWrapper',
          children: [
            {
              name: 'title',
              className: 'teams2-title l7frovgley7-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>
                            Hernán
                          </p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams2-job l7fshrwrlv-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        Head of Design
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams2-content l7fshukp6a8-editor_css',
              children: (
                <span>
                  <span>
                    <p>Extended Team</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block9',
        className: 'block',
        md: 8,
        xs: 24,
        image: {
          name: 'image',
          className: 'teams2-image',
          children: 'https://cryptohouse.services/team/avatar-female.svg',
        },
        titleWrapper: {
          className: 'teams2-textWrapper',
          children: [
            {
              name: 'title',
              className: 'teams2-title l7frn3nxl6-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>
                            Shifa
                          </p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams2-job l7fs3et6h77-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>
                            Africa Liaison
                          </p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams2-content l7fshmiphim-editor_css',
              children: (
                <span>
                  <p>Extended Team</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 8,
        xs: 24,
        image: {
          name: 'image',
          className: 'teams2-image',
          children:
            'https://cryptohouse.services/team/avatar-male.svg',
        },
        titleWrapper: {
          className: 'teams2-textWrapper',
          children: [
            {
              name: 'title',
              className: 'teams2-title l7frpsvnzj-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          Diego
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams2-job l7fshws72lc-editor_css',
              children: (
                <span>
                  <span>
                    <p>
                      Community Building Specialist
                    </p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams2-content l7fshzqyyrp-editor_css',
              children: (
                <span>
                  <p>Extended Team</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block6',
        className: 'block',
        md: 8,
        xs: 24,
        image: {
          name: 'image',
          className: 'teams2-image',
          children:
            'https://cryptohouse.services/team/avatar-male.svg',
        },
        titleWrapper: {
          className: 'teams2-textWrapper',
          children: [
            {
              name: 'title',
              className: 'teams2-title l7frru064te-editor_css',
              children: (
                <span>
                  <span>
                    <p>LKZ</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams2-job l7fsi7w8rc-editor_css',
              children: (
                <span>
                  <p>
                    Community Engagement Specialist
                  </p>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams2-content l7fsia92m5-editor_css',
              children: (
                <span>
                  <p>Extended Team</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block7',
        className: 'block',
        md: 8,
        xs: 24,
        image: {
          name: 'image',
          className: 'teams2-image',
          children:
            'https://cryptohouse.services/team/avatar-male.svg',
        },
        titleWrapper: {
          className: 'teams2-textWrapper',
          children: [
            {
              name: 'title',
              className: 'teams2-title l7frstr3fb-editor_css',
              children: (
                <span>
                  <p>
                    Meta
                  </p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams2-job l7fsidkvbdf-editor_css',
              children: (
                <span>
                  <p>
                    Community Engagement Specialist
                  </p>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams2-content l7fsifu695p-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <p>Extended Team</p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block8',
        className: 'block',
        md: 8,
        xs: 24,
        image: {
          name: 'image',
          className: 'teams2-image',
          children:
            'https://cryptohouse.services/team/Napoles.png',
        },
        titleWrapper: {
          className: 'teams2-textWrapper',
          children: [
            {
              name: 'title',
              className: 'teams2-title l7frtoxy5yj-editor_css',
              children: (
                <span>
                  <p>Nápoles</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams2-job l7fsihzejp-editor_css',
              children: (
                <span>
                  <p>
                    Community Engagement Specialist
                  </p>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams2-content l7fsijwhl4n-editor_css',
              children: (
                <span>
                  <p>Extended Team</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block10',
        className: 'block',
        md: 8,
        xs: 24,
        image: {
          name: 'image',
          className: 'teams2-image',
          children: 'https://cryptohouse.services/team/avatar-male.svg',
        },
        titleWrapper: {
          className: 'teams2-textWrapper',
          children: [
            {
              name: 'title',
              className: 'teams2-title l7frn3nxl6-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>
                            Rodrigo
                          </p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams2-job l7fs3et6h77-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>
                            Legal Counsel
                          </p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams2-content l7fshmiphim-editor_css',
              children: (
                <span>
                  <p>Extended Team</p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Content120DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper l7frz6516is-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
/*      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image l7frz5n9rnq-editor_css',
      },*/
      {
        name: 'title',
        children: (
          <span>
            <span>
              <h2>
                Catalyst
              </h2>
            </span>
          </span>
        ),
        className: 'title-h1 l7frz0qvbt-editor_css',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>
              Project Catalyst is Cardano's funding and governance platform for
              projects developed on top of it's network.<br></br> Projects get funded by
              being voted for by $ADA holders.&nbsp;
            </p>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (
          <span>
            <span>
              <p>You can support <i><b>Crypto</b></i><span className="house">House</span> by voting us in Fund 10</p>
            </span>
          </span>
        ),
        className: 'title-content',
      },
    ],
  },
  button: {
    className: '',
    children: {
      a: {
        className: 'button',
        href: 'https://cardano.ideascale.com',
        target: "_blank",
        children: (
          <span>
            <p>Next Fund</p>
          </span>
        ),
      },
    },
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 8,
        className: 'block l4dcs5u5qum-editor_css',
        title: {
          className: 'logo jzl0qcpyjra-editor_css',
          children:
            <img src={isologoRed} alt="Powered by Cardano"></img>,
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <span>
                    <span>
                      <p>Powered by Cardano</p>
                    </span>
                  </span>
                </span>
              ),
              className: 'l4dcrif49co-editor_css',
            },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 8,
        className: 'block',
        title: {
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <p>Funding Proposals</p>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              href: 'https://cardano.ideascale.com',
              name: 'link0',
              children: (
                <span>
                  <p>Catalyst</p>
                </span>
              ),
              target: '_blank',
            },
          ],
        },
      },
      {
        name: 'contact',
        xs: 24,
        md: 8,
        className: 'block',
        title: {
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <p>Contact Methods</p>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              "name": "image~l7hsooubii",
              "className": "",
              "href":"mailto:info@cryptohouse.services",
              "children":
                <img src='https://cryptohouse.services/images/mail-icon.svg' alt="Send us an email" width="40px"></img>
            },
            {
              href: 'https://twitter.com/Cryptohouse_eng',
              name: 'link1',
              children: (
                <span>
                  <span>
                  <img src='https://cryptohouse.services/images/twitter-icon.svg' alt="Follow us on Twitter" width="40px"></img>
                  </span>
                </span>
              ),
              target: '_blank',
            },
            {
              href: 'https://discord.gg/Eq2SPtApnG',
              name: 'link2',
              children: (
                <span>
                  <span>
                  <img src='https://cryptohouse.services/images/discord-icon.svg' alt="Join our Discord server" width="40px"></img>
                  </span>
                </span>
              ),
              target: '_blank',
            },
            {
              href: 'https://t.me/CryptoHouseOfficial',
              name: 'link3',
              children: (
                <span>
                  <span>
                  <img src='https://cryptohouse.services/images/tg-icon.svg' alt="Join our Telegram group" width="40px"></img>
                  </span>
                </span>
              ),
              target: '_blank',
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <span>
          <span>
            <span>
              <span>Copyright © 2022 <i><b>Crypto</b></i><span className="house">House</span></span>
              <br />
            </span>
          </span>
        </span>
      </span>
    ),
  },
};
